import { ref, getDownloadURL } from "firebase/storage";

import { getDoc, doc } from "firebase/firestore";

import { db, storage, functions } from "../main";
import { httpsCallable } from "firebase/functions";
import { Collaboration, Inquiry } from "@/types";

export async function getMainVideoMontage() {
  const main = { url: "", poster: "" };

  try {
    const docRef = doc(db, "content", "videos");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as { main: { poster: string; url: string } };

    const urlString = data.main;

    const gsReferenceUrl = ref(storage, `content/videos/${urlString.url}`);
    const gsReferencePoster = ref(
      storage,
      `content/posters/${urlString.poster}`
    );

    main.url = await getDownloadURL(gsReferenceUrl);
    main.poster = await getDownloadURL(gsReferencePoster);
  } catch (_e) {}

  return main;
}

export async function getVisionVideos() {
  let urls = [];

  try {
    const docRef = doc(db, "content", "videos");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as {
      visions: { poster: string; url: string; id: string }[];
    };

    const visionVideos = data.visions;

    for (const vision of visionVideos) {
      const gsReferenceUrl = ref(storage, `content/videos/${vision.url}`);
      const gsReferencePoster = ref(
        storage,
        `content/posters/${vision.poster}`
      );

      const url = await getDownloadURL(gsReferenceUrl);
      const poster = await getDownloadURL(gsReferencePoster);
      const id = vision.id;

      urls.push({ url: url, id: id, poster: poster });
    }
  } catch (_e) {}

  return urls;
}

export async function getPrivacyPolicy() {
  try {
    const gsReferenceUrl = ref(
      storage,
      `C Squared Media, LLC/Privacy Policy.pdf`
    );
    const url = await getDownloadURL(gsReferenceUrl);

    return url;
  } catch (_e) {
    return null;
  }
}

export async function getPrivacyAndProceduresPolicy() {
  try {
    const gsReferenceUrl = ref(
      storage,
      `C Squared Media, LLC/Policies and Procedures.pdf`
    );
    const url = await getDownloadURL(gsReferenceUrl);

    return url;
  } catch (_e) {
    return null;
  }
}

export async function uploadCollaboration(
  collaboration: Collaboration,
  token: string
) {
  try {
    const send = httpsCallable(functions, "sendSupportMsg");
    const res = await send({
      type: "collaborations",
      info: collaboration,
      token: token,
    });
    const data = res.data as any;

    if (data && "httpsErrorCode" in data) {
      return true;
    }

    return data.success ?? false;
  } catch (_e) {
    return true;
  }
}

export async function uploadInquiry(inquiry: Inquiry, token: string) {
  try {
    const send = httpsCallable(functions, "sendSupportMsg");
    const res = await send({ type: "inquiries", info: inquiry, token: token });
    const data = res.data as any;

    if (data && "httpsErrorCode" in data) {
      return true;
    }

    return data.success ?? false;
  } catch (_e) {
    return true;
  }
}
