<script setup lang="ts">
import {
  getVisionVideos,
  uploadCollaboration,
} from "../composables/useFirebase";
import { Ref, ref, onBeforeMount, onMounted } from "vue";
import validator from "validator";
import { Head } from "@unhead/vue/components";
import { Collaboration } from "../types";

const visionVideos: Ref<
  | {
      url: string;
      id: string;
      poster: string;
    }[]
  | null
> = ref(null);
const collaboration = ref<Collaboration>({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  services: [],
  projectDescription: "",
  startDate: "",
  completionDate: "",
});

const showCollaborationError = ref(false);
const showSuccessCollaborationSent = ref(false);
const minDate = ref("");
const collaborationErrors = ref<string[]>([]);
const showCollaborationsErrors = ref(false);

const services = [
  {
    id: "video-production",
    name: "Video Production",
    value: "Video Production",
  },
  { id: "video-editing", name: "Video Editing", value: "Video Editing" },
  {
    id: "content-marketing",
    name: "Content Marketing",
    value: "Content Marketing",
  },
  {
    id: "digital-marketing",
    name: "Digital Marketing",
    value: "Digital Marketing",
  },
  { id: "analytics", name: "Media/Website Analytics", value: "Analytics" },
];

onBeforeMount(async () => {
  visionVideos.value = await getVisionVideos();

  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const dd = String(today.getDate()).padStart(2, "0");

  minDate.value = `${yyyy}-${mm}-${dd}`;
});

onMounted(() => {
  let elementsToMove = document.querySelectorAll(".vision-text, .vision-video");

  elementsToMove.forEach(function (element) {
    let observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          if (entry.intersectionRatio > 0) {
            element.classList.remove("move-up");
          } else {
            element.classList.add("move-up");
          }
        });
      },
      { threshold: 0 }
    );

    observer.observe(element);
  });
});

function scrollToBooking() {
  const connectElement = document.querySelector(".connect");

  if (!connectElement) return;

  connectElement.scrollIntoView({
    behavior: "smooth",
  });
}

async function onCaptchaSubmit(token: string) {
  const error = await uploadCollaboration(collaboration.value, token);

  if (error) {
    showCollaborationError.value = true;
  } else {
    showSuccessCollaborationSent.value = true;

    const container = document.getElementById("connect");

    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  }
}

function toggleCaptcha() {
  showCollaborationsErrors.value = false;
  collaborationErrors.value = [];

  const errors = validateCollaboration(collaboration.value);

  if (errors.length === 0) {
    const grecaptcha = window.grecaptcha

    if (grecaptcha) {
      grecaptcha.render("grecaptcha-container", {
        sitekey: `${process.env.VUE_APP_RECAPTCHA_KEY}`,
        callback: onCaptchaSubmit,
      });
    } else {
      showCollaborationError.value = true;
    }
  } else {
    showCollaborationsErrors.value = true;
    collaborationErrors.value = errors;
  }
}

function preventNonNumeric(event: KeyboardEvent) {
  const allowedCharacters = /[0-9]/;
  if (
    !allowedCharacters.test(event.key) ||
    collaboration.value.phoneNumber.length > 11
  ) {
    event.preventDefault();
  }
}

function formatPhoneNumber() {
  const phoneNumber = collaboration.value.phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  const formattedPhoneNumber = phoneNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "$1-$2-$3"
  ); // Format as XXX-XXX-XXXX
  collaboration.value.phoneNumber = formattedPhoneNumber;
}

function validateCollaboration(collaboration: Collaboration) {
  const errors = [];

  if (validator.isEmpty(collaboration.firstName)) {
    errors.push("First Name is required.");
  } else if (
    !validator.isAlpha(collaboration.firstName, "en-US", { ignore: "-' " })
  ) {
    errors.push(
      "First Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(collaboration.lastName)) {
    errors.push("Last Name is required.");
  } else if (
    !validator.isAlpha(collaboration.lastName, "en-US", { ignore: "-' " })
  ) {
    errors.push(
      "Last Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(collaboration.email)) {
    errors.push("Email is required.");
  } else if (!validator.isEmail(collaboration.email)) {
    errors.push("Invalid email format.");
  }

  if (validator.isEmpty(collaboration.projectDescription)) {
    errors.push("Project Description is required.");
  } else if (collaboration.projectDescription.length > 750) {
    errors.push(
      "Project Description character limit exceeded. Maximum 750 characters allowed."
    );
  }

  if (
    !validator.isEmpty(collaboration.phoneNumber) &&
    !validator.isMobilePhone(collaboration.phoneNumber, "any", {
      strictMode: false,
    })
  ) {
    errors.push("Invalid phone number format. Required format: XXX-XXX-XXXX");
  }

  // Start Date and Completion Date
  if (
    !validator.isEmpty(collaboration.startDate) &&
    !validator.isISO8601(collaboration.startDate)
  ) {
    errors.push("Invalid date format.");
  }
  if (
    !validator.isEmpty(collaboration.completionDate) &&
    !validator.isISO8601(collaboration.completionDate)
  ) {
    errors.push("Invalid date format.");
  }

  // Services (at least one selected)
  if (
    !Array.isArray(collaboration.services) ||
    collaboration.services.length < 1
  ) {
    errors.push("Select at least one service.");
  } else {
    // Validate the selected services
    for (const service of collaboration.services) {
      if (
        ![
          "Video Production",
          "Video Editing",
          "Analytics",
          "Digital Marketing",
          "Content Marketing",
        ].includes(service)
      ) {
        errors.push("Invalid service selected.");
        break;
      }
    }
  }

  return errors;
}
</script>

<template>
  <Head>
    <title>Home | C Squared Media, LLC</title>
    <meta
      name="description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
    <meta property="og:title" content="Home | C Squared Media, LLC" />
    <meta
      property="og:description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
    <meta name="twitter:title" content="Home | C Squared Media, LLC" />
    <meta
      name="twitter:description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
  </Head>
  <div class="home">
    <section class="main">
      <div class="main-bg">
        <span class="slogan-text">
          <span
            >CAPTURING LIFE'S<br />MOMENTS AND SHARING<br />YOUR STORIES</span
          >
          <span @click="scrollToBooking" class="book-now-text">Book Now</span>
        </span>
        <img src="@/assets/csquared-bg.png" alt="C squared media" />
      </div>

      <div class="main-founder-photo">
        <img src="@/assets/csquared-founder-1.jpg" alt="woman holding camera" />
      </div>
    </section>
    <section class="mission-vision">
      <div class="mission-heading">
        <span class="mission-text">Empower.</span>
        <span class="mission-text">Elevate.</span>
      </div>
      <div class="mission-images">
        <div class="image-container">
          <div class="image">
            <div class="image-voices">
              <img
                src="@/assets/voices-bg.png"
                alt="profile of a woman talking"
              />
            </div>
            <span class="image-text">VOICES</span>
            <div class="image-overlay"></div>
          </div>
        </div>
        <div class="image-container">
          <div class="image">
            <div class="image-ideas">
              <img
                src="@/assets/ideas-bg.png"
                alt="Man looking over a balcony"
              />
            </div>
            <span class="image-text">IDEAS</span>
            <div class="image-overlay"></div>
          </div>
        </div>
        <div class="image-container">
          <div class="image">
            <div class="image-narratives">
              <img
                src="@/assets/narratives-bg.png"
                alt="man barber cutting a young man&#39;s hair"
              />
            </div>
            <span class="image-text">NARRATIVES</span>
            <div class="image-overlay"></div>
          </div>
        </div>
      </div>
      <div class="vision-section">
        <span class="vision-heading">Our Vision</span>
        <span class="vision-text"
          >At C Squared Media, LLC, we champion the belief that every narrative
          deserves to become an unveiled masterpiece. Our mission is to be your
          creative partner, serving individuals, non-profits, small businesses,
          and diverse organizations on their storytelling journeys.</span
        >
        <div class="vision-video">
          <video
            v-if="visionVideos"
            id="main-montage"
            :poster="visionVideos[0].poster"
            :src="visionVideos[0].url"
            type="video/mp4"
            controls
            preload="metadata"
          >
            <!-- <track
              label="English"
              kind="subtitles"
              srclang="en"
              src="captions/vtt/sintel-en.vtt"
              default
            /> -->
          </video>
          <div v-else class="video-loading placeholder-wave">
            <div class="placeholder"></div>
          </div>
        </div>
        <span class="vision-text">
          We'll amplify your unique voice, connecting it with your desired
          audience while we take the reins on media tasks, removing one more
          item from your plate, giving you your time back so you can focus on
          your business in a way that makes more sense.
        </span>
        <div class="vision-video">
          <video
            v-if="visionVideos"
            id="main-montage"
            :poster="visionVideos[1].poster"
            :src="visionVideos[1].url"
            type="video/mp4"
            controls
            preload="metadata"
          >
            <!-- <track
              label="English"
              kind="subtitles"
              srclang="en"
              src="captions/vtt/sintel-en.vtt"
              default
            /> -->
          </video>
          <div v-else class="video-loading placeholder-wave">
            <div class="placeholder"></div>
          </div>
        </div>

        <span class="vision-text mission-statement">
          Our mission is to empower and elevate the voices, ideas, and
          narratives of individuals through impactful media representation.
        </span>
        <div class="vision-video">
          <video
            v-if="visionVideos"
            id="main-montage"
            :poster="visionVideos[2].poster"
            :src="visionVideos[2].url"
            type="video/mp4"
            controls
            preload="metadata"
          >
            <!-- <track
              label="English"
              kind="subtitles"
              srclang="en"
              src="captions/vtt/sintel-en.vtt"
              default
            /> -->
          </video>
          <div v-else class="video-loading placeholder-wave">
            <div class="placeholder"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials">
      <header class="testimonials-header">Testimonials</header>
      <div class="testimonials-container">
        <div class="testimonial">
          <span class="testimonial-text">
            “Having Courtney help me understand the analytics of my social media
            page has given me a deeper understanding of my brand as a whole. As
            a creator only one year into building my own brand on social media,
            I was unaware that I can take what the app gives me and use the
            information to benefit me. It was very helpful for everything to be
            broken down into small and understandable pieces.”
          </span>
          <span class="testimonial-author"
            >Shelby Lattimore, TikTok Creator</span
          >
        </div>
        <div class="testimonial">
          <span class="testimonial-text">
            “After seeing Courtney&#39;s video produced for Michael Bush&#39;s
            Board of Supervisors campaign, I was so impressed with the quality
            and the way she captured and projected his personality and his
            message, I wanted her to do the same for my company. While I
            appreciated Courtney&#39;s professionalism, it&#39;s the quality of
            her work in the way that she sees how to feature the best parts of
            the message potential customers need to hear while capturing our
            business presence. Everything else about getting the work done was
            also effortless - planning the shoot, editing, and timing of
            delivery. I&#39;m looking forward to taping our next video in our
            emerging investor financial education series - and for future
            campaigns.”
          </span>
          <span class="testimonial-author"
            >Nicole Cole, Nicole Cole Financial Services Group/LPL
            Financial</span
          >
        </div>
        <div class="testimonial">
          <span class="testimonial-text">
            “Working with Courtney on our barbershop's promotional video was an
            outstanding experience. She brought our vision to life with
            creativity and professionalism, capturing our shop's essence
            perfectly. From start to finish, Courtney was a pleasure to work
            with, and the final video exceeded our expectations. We highly
            recommend her services and look forward to future collaborations.
            Thank you, Courtney, for your exceptional work!”
          </span>
          <span class="testimonial-author"
            >Philco Goins, Co-Owner of Up North Kutz</span
          >
        </div>
        <div class="testimonial">
          <span class="testimonial-text">
            “Courtney has provided exceptional services with a speedy turnaround
            to my videography needs. She highlighted the best parts of me, and
            with her creative touch, she used my suggestions and demonstrated
            true teamwork and effective listening. Everything from her
            timeliness on scene, to the structuring of our video plan and
            follow-ups for anything needing changes or additions, was as
            professional as they come. I would refer Courtney&#39;s services to
            anyone looking to produce a video they want to promote to the world
            and archive for their portfolio.”
          </span>
          <span class="testimonial-author"
            >Michael Bush, Spotsylvania County Board of Supervisors
            Candidate</span
          >
        </div>
      </div>
    </section>
    <section class="founder-img">
      <img
        src="@/assets/csquared-founder-5.jpg"
        alt="woman sitting at a table posing for a photo"
      />
    </section>
    <section class="connect" id="connect">
      <div
        v-if="!showSuccessCollaborationSent && !showCollaborationError"
        class="connect-main"
      >
        <span class="connect-heading"> Have An Idea?</span>
        <span class="connect-subheading"
          >Book A Free 30 Minute Consultation</span
        >
        <span class="connect-description">
          Our motto is “capturing life&#39;s moments and sharing your stories.”
          We will use this time to learn more about you, your business, and
          vision for your desired storytelling experience. Please fill out the
          form with as many details as possible.
        </span>
      </div>
      <form
        v-if="!showSuccessCollaborationSent && !showCollaborationError"
        class="connect-form"
        @submit.prevent="toggleCaptcha"
        action="?"
        method="POST"
      >
        <div class="form-group">
          <label for="firstName">First Name</label>

          <input
            v-model="collaboration.firstName"
            type="text"
            class="form-control"
            id="firstName"
            autocomplete="given-name"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>

          <input
            v-model="collaboration.lastName"
            type="text"
            class="form-control"
            id="lastName"
            required
            autocomplete="family-name"
          />
        </div>
        <div class="form-group">
          <label for="email">Email</label>

          <input
            v-model="collaboration.email"
            type="email"
            class="form-control"
            id="email"
            required
            autocomplete="email"
          />
        </div>
        <div class="form-group">
          <label for="phoneNumber">Phone Number</label>

          <input
            v-model="collaboration.phoneNumber"
            @input="formatPhoneNumber()"
            type="tel"
            class="form-control"
            id="phoneNumber"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            @keypress="preventNonNumeric($event)"
            title="XXX-XXX-XXXX"
            autocomplete="tel-national"
            required
          />
        </div>
        <div class="form-group">
          <label class="form-label"
            >What service(s) are you interested in?</label
          >
          <div class="form-control services">
            <div
              class="form-check"
              v-for="service in services"
              :key="service.id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="service.id"
                v-model="collaboration.services"
                :value="service.value"
              />
              <label class="form-check-label" :for="service.id">{{
                service.name
              }}</label>
            </div>
          </div>
        </div>
        <div class="form-group project-description-textarea">
          <label for="project-description"
            >Describe your project and its purpose.
          </label>
          <textarea
            v-model="collaboration.projectDescription"
            class="form-control"
            id="project-description"
            rows="8"
            maxlength="750"
            required
          >
          </textarea>
          <div
            :class="`project-description-count ${
              collaboration.projectDescription.length > 730
                ? 'max-limit-hit'
                : ''
            }`"
          >
            ({{ collaboration.projectDescription.length }}/750)
          </div>
        </div>
        <div class="form-group">
          <label for="start-date">When do you want to get started? </label>

          <input
            v-model="collaboration.startDate"
            type="date"
            class="form-control"
            id="start-date"
            :min="minDate"
            required
          />
        </div>
        <div class="form-group">
          <label for="completion-date"
            >When do you need your project completed?
          </label>
          <input
            v-model="collaboration.completionDate"
            type="date"
            class="form-control"
            id="completion-date"
            :min="minDate"
            required
          />
        </div>
        <div v-if="showCollaborationsErrors" class="collaborations-errors">
          <span
            v-for="(collaborationError, errorIndex) in collaborationErrors"
            :key="errorIndex"
            class="collaboration-error-text"
          >
            {{ collaborationError }}</span
          >
        </div>
        <div class="google-recaptcha-container" id="grecaptcha-container"></div>
        <button type="submit" class="btn btn-secondary btn-send">Send</button>
      </form>
      <div
        v-if="showSuccessCollaborationSent || showCollaborationError"
        class="form-responses"
      >
        <div v-if="showSuccessCollaborationSent" class="successful-response">
          <span class="success-header">Thank You!</span>
          <span class="success-description"
            >We look forward to following up with you.</span
          >
        </div>
        <div v-if="showCollaborationError" class="failed-response">
          <span class="failed-header">Oops! Something went wrong.</span>
          <span class="failed-description"
            >We encountered an issue while processing your consultation request.
            Please contact us directly using the listed contact methods below
            for assistance.</span
          >
        </div>
      </div>
      <div class="connect-us-images">
        <img
          class="connect-us-founder-img"
          src="@/assets/csquared-founder-3.jpg"
          alt="woman sitting at a table posing for a photo"
        />
      </div>
    </section>
  </div>
</template>

<style scoped>
.home {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: #e5e6e8;
  scroll-behavior: smooth;
}

.divider {
  height: 0.2rem;
  background: #001f3f;
  margin: 0 3.5rem;
  border-radius: 8%;
}

.main {
  width: 100%;
  display: flex;
  background: rgba(0, 31, 63, 1);
  align-items: flex-end;
  flex-flow: row wrap;
  align-content: flex-end;
  position: relative;
  padding-top: 3.1rem;
}

.splash-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.main-bg {
  min-height: 60vh;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
}

.main-bg img {
  max-height: 90%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.slogan-text {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #f4ebd0;
  font-size: 24px;
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8rem;
  background: rgba(0, 31, 63, 0.3);
  text-shadow: 0 0 7px #ff5733, 0 0 10px #ff5733, 0 0 21px #ff5733,
    0 0 42px #ff5733, 0 0 82px #ff5733, 0 0 92px #ff5733, 0 0 102px #ff5733,
    0 0 151px #ff5733;
  flex-flow: row wrap;
  align-content: center;
}

.slogan-text span:first-of-type {
  width: 100%;
}

.slogan-text .book-now-text {
  text-shadow: none;
  margin-top: 1.8rem;
  background: rgb(255, 87, 51, 0.85);
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  animation: scalingAnimation 1.5s alternate infinite ease-in;
  color: #f4ebd0;
}

.video-loading {
  height: 12rem;
  width: 100%;
}

.placeholder-glow {
  width: 100%;
  height: 100%;
  padding: 8rem 5rem;
}

.placeholder {
  height: 100%;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.main-founder-photo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0;
  position: relative;
  z-index: 3;
  background: rgba(0, 31, 63, 0.3);
  margin-bottom: 2.5rem;
}

.main-founder-photo img {
  max-height: 30rem;
  max-width: 98%;
  object-fit: contain;
}

.mission-vision {
  background: #a9a9a9;
}

.mission-vision,
.vision-statement {
  width: 100%;
  position: relative;
}

.mission-heading {
  position: absolute;
  z-index: 4;
  top: 1rem;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  flex-flow: row wrap;
}

.mission-text {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #ff5733;
  width: 100%;
  font-size: 34px;
}

.mission-images {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  width: 100%;
  height: 100%;
  position: sticky;
  bottom: 0;
  z-index: 3;
  border-radius: 2%;
}

.image-container:nth-of-type(2) {
  z-index: 2;
}

.image-container:nth-of-type(3) {
  z-index: 1;
}

.image {
  width: calc(18rem + 1vw);
  height: calc(23rem + 1vh);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
  box-shadow: 0 0 15px #ff5733;
}

.image-text {
  position: relative;
  z-index: 3;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 38px;
  color: #a9a9a9;
  text-shadow: 0 0 7px #ff5733, 0 0 10px #ff5733, 0 0 21px #ff5733;
}

.image-overlay {
  position: absolute;
  top: 0;
  width: calc(18rem + 1vw);
  height: calc(23rem + 1vh);
  background: #ff884d;
  opacity: 0.3;
  z-index: 2;
  border-radius: 2%;
}

.image-voices,
.image-ideas,
.image-narratives {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: white;
  border-radius: 2%;
}

.image-voices img,
.image-ideas img,
.image-narratives img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2%;
}

.vision-section {
  display: flex;
  flex-flow: row wrap;
  padding: 34px 0;
  background: #ff5733;
}

.vision-heading {
  font-size: 30px;
  color: #f4ebd0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  width: 100%;
  text-align: left;
  margin-left: 5px;
  padding-left: 20px;
  text-shadow: 0 0 10px #080401;
}

.vision-text {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  padding: 20px;
  font-size: 18px;
  margin: 10px 5px;
  color: #f4ebd0;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.vision-section .vision-text {
  text-align: left;
}

.vision-section .vision-text ~ .vision-text {
  text-align: right;
}

.vision-section .vision-text.mission-statement {
  text-align: center;
  padding: 18px 30px;
}

.vision-video {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12px 10px;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.move-up {
  transform: translateY(50px);
}

.vision-video video {
  width: 100%;
  max-height: 25rem;
  max-width: 40rem;
}

.testimonials {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  background: rgba(0, 31, 63, 1);
  color: #f4ebd0;
  padding: 34px 0;
}

.testimonials-header {
  font-size: 30px;
  color: #f4ebd0;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  width: 100%;
  text-align: left;
  margin-left: 5px;
  padding-left: 20px;
  text-shadow: 0 0 10px #080401;
  margin-bottom: 10px;
}

.testimonial {
  padding: 0 20px;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-flow: row wrap;
}

.testimonial-text {
  font-style: italic;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.testimonial-author {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #ff884d;
  width: 100%;
  margin-top: 5px;
}

.founder-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 31, 63, 1);
}

.founder-img img {
  max-height: 100%;
  max-width: 100%;
}

.connect {
  width: 100%;
  padding: 20px 0px;
  background: #a9a9a9;
  scroll-margin-top: 2rem;
}

.connect-main {
  display: flex;
  flex-flow: row wrap;
  padding-top: 34px;
  width: 100%;
}

.connect-heading {
  font-size: 30px;
  color: #001f3f;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  width: 100%;
  margin-bottom: 2px;
  text-align: left;
  padding: 0px 2rem;
}

.connect-subheading {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #003366;
  text-align: left;
  padding: 0px 2rem;
}

.connect-description {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  padding: 0px 2rem;
  padding-right: 3rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: left;
  font-size: 14px;
}

.connect-form {
  padding: 15px 35px;
  font-family: "Roboto", sans-serif;
}

.connect-form input,
.connect-form textarea {
  color: rgba(var(--bs-body-color-rgb), 0.95);
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

textarea {
  resize: none;
}

.form-group {
  margin-bottom: 18px;
}

.form-group input,
.form-group textarea,
.form-group select {
  background-color: #a9a9a9;
  font-size: 12px;
  text-align: start;
}

.form-group .form-check-input {
  border-color: #a9a9a9;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  box-shadow: none;
  border: none;
  border: 2px solid #ff5733;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-group.project-description-textarea {
  position: relative;
  margin-bottom: 22px;
}

.form-group .project-description-count {
  position: absolute;
  bottom: -20px;
  right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.project-description-count.max-limit-hit {
  color: darkred;
}

.form-group label,
.form-label {
  text-align: left;
  width: 100%;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.form-control {
  border: none;
  border: 1px solid #2c3e50;
}

.form-control.services {
  padding: 0;
  background: rgb(169, 169, 169);
  margin-bottom: 12px;
}

.form-check {
  padding-left: 2rem;
  color: rgba(var(--bs-body-color-rgb), 0.65);
  margin: 5px 0;
}

.form-check:first-of-type {
  margin-top: 10px;
}

.form-check:last-of-type {
  margin-bottom: 10px;
}

.form-check .form-check-input {
  background-color: #c9c9c9;
}

.form-check .form-check-input:checked {
  background-color: #ff5733;
  border-color: #ff5733;
}

.form-check .form-check-input:focus,
.form-check .form-check-input:before {
  box-shadow: none !important;
  border-color: #ff5733;
}

.form-check,
.services-checks {
  text-align: left;
  font-size: 12px;
  width: 100%;
}

.services-checks {
  width: 100%;
  display: flex;
  padding: 1rem 0.75rem;
  color: rgba(var(--bs-body-color-rgb), 0.65);
  font-size: 14px;
}

.connect-us-images {
  display: none;
}

.collaborations-errors {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.collaboration-error-text {
  font-size: 12px;
  color: darkred;
  width: 100%;
}

.successful-response,
.failed-response {
  width: 100%;
  padding: 1rem 1.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.success-header,
.failed-header {
  width: 100%;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
}

.btn-send {
  background-color: rgb(0, 31, 66);
  margin-top: 10px;
}

.btn-send:disabled {
  background-color: rgb(0, 31, 66);
}

@keyframes scalingAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@media only screen and (min-width: 1010px) {
  .main {
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
  }

  .main-bg {
    height: 100%;
    width: 60%;
    margin-bottom: unset;
  }

  .divider {
    margin: 0 26rem;
  }

  .slogan-text {
    font-size: 22px;
  }

  .main-founder-photo {
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    margin-bottom: unset;
  }

  .main-founder-photo img {
    box-shadow: 0 0 10px #ff5733;
    border-radius: 1%;
  }

  .mission-heading {
    flex-flow: unset;
    position: unset;
  }

  .mission-images {
    margin-top: 0;
  }

  .mission-vision .mission-text:first-of-type {
    text-align: right;
  }

  .mission-text {
    margin: 0 5px;
    font-size: 40px;
  }

  .image-container {
    flex: 1;
    width: auto;
    justify-content: flex-end;
    position: relative;
  }

  .image-container:nth-child(2) {
    justify-content: center;
  }

  .image-container:nth-child(3) {
    justify-content: flex-start;
  }

  .vision-heading {
    text-align: center;
    font-size: 42px;
  }

  .vision-section .vision-text {
    text-align: left;
    font-size: 22px;
    max-width: 50%;
    margin-left: 7rem;
  }

  .vision-video video {
    max-height: unset;
    max-width: 50rem;
  }

  .vision-section .vision-text ~ .vision-text {
    margin-left: auto;
    margin-right: 8rem;
  }

  .vision-section .vision-text.mission-statement {
    text-align: left;
    margin-left: 7rem;
  }

  .testimonials {
    padding: 40px;
  }

  .testimonials-header {
    padding-left: unset;
    text-align: center;
  }

  .testimonial {
    max-width: 100rem;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .connect-heading,
  .connect-subheading {
    text-align: center;
    width: 100%;
  }

  .connect-description {
    font-size: 18px;
  }

  .connect {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .connect-form,
  .connect-description {
    width: 70%;
    max-width: 40rem;
  }

  .connect-description {
    margin: 10px auto;
    text-align: center;
    max-width: 50rem;
  }

  .connect-us-images {
    display: flex;
    width: 30%;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  .connect-us-founder-img {
    max-height: 24rem;
    max-width: 100%;
    border-radius: 5px;
  }

  .founder-img {
    display: none;
  }

  .form-responses {
    width: 40%;
  }

  .failed-response,
  .successful-response {
    height: 100%;
    align-content: center;
  }
}
</style>
