import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import PrivacyProceduresPolicyView from "../views/PrivacyProceduresPolicyView.vue";
import FounderView from "../views/FounderView.vue";
import FAQView from "../views/FAQView.vue";
import ServicesView from "../views/ServicesView.vue";
import ContactUsView from "../views/ContactUsView.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/founder",
    name: "founder",
    component: FounderView,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUsView,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolicyView,
  },
  {
    path: "/privacy-procedures",
    name: "privacy-procedures",
    component: PrivacyProceduresPolicyView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((_to, _from, next) => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });

  next();
});

router.afterEach((to) => {
  const description =
    to.meta.description ||
    "C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach.";
  const metaTag = document.querySelector('meta[name="description"]');

  if (metaTag) {
    metaTag.setAttribute("content", `${description}`);
  }
});

export default router;
