<script setup lang="ts">
import { getPrivacyPolicy } from "@/composables/useFirebase";
import { ref, onMounted } from "vue";
import { Head } from "@unhead/vue/components";

const privacyPolicySrc = ref<string | null>(null);
const loading = ref(true);

onMounted(async () => {
  privacyPolicySrc.value = await getPrivacyPolicy();
  loading.value = false;
});
</script>

<template>
  <Head>
    <title>Privacy Policy | C Squared Media, LLC</title>
    <meta
      name="description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
    <meta property="og:title" content="Privacy Policy | C Squared Media, LLC" />
    <meta
      property="og:description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
    <meta
      name="twitter:title"
      content="Privacy Policy | C Squared Media, LLC"
    />
    <meta
      name="twitter:description"
      content="C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
    />
  </Head>
  <section class="policy">
    <div v-if="loading" class="loading-policy">
      <div class="spinner-grow" role="status"></div>
      <div class="spinner-grow" role="status"></div>
      <div class="spinner-grow" role="status"></div>
    </div>
    <div v-else class="policy-container">
      <iframe
        v-if="privacyPolicySrc !== null"
        :src="`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(
          privacyPolicySrc
        )}&embedded=true`"
        type="application/pdf"
        class="policy-embed"
      >
      </iframe>
      <div v-else class="policy-load-error">
        <span class="policy-load-error-header"
          >Oops! Something went wrong.</span
        >
        <span class="policy-load-error-description"
          >We could not load the privacy policy. <br />Please contact us
          directly using the listed contact methods below for assistance.
        </span>
      </div>
    </div>
  </section>
</template>

<style scoped>
.policy {
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
  background: #a9a9a9;
  padding-top: 4.5rem;
  align-content: flex-start;
}

.policy-container,
.loading-policy,
.policy-load-error {
  width: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.loading-policy div {
  margin: 0 3px;
}

.policy-embed {
  width: 100%;
  height: 100%;
}

.policy-load-error {
  flex-flow: row wrap;
  align-content: center;
}

.policy-load-error-header {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  width: 100%;
  margin-bottom: 5px;
}
</style>
