<template>
  <Navbar />
  <router-view />
  <Footer />
</template>

<script setup lang="ts">
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import { useSeoMeta } from '@unhead/vue';
import { onBeforeMount } from 'vue';

onBeforeMount(() => {
  useSeoMeta({
    ogType: 'website',
    ogImage: 'https://firebasestorage.googleapis.com/v0/b/csquaredmediallc.appspot.com/o/C2%20Media%20Logo_BC.png?alt=media&token=a3a9308b-cef8-4db3-a7d4-082c81e8a931',
    ogUrl: 'https://c-squared-media.com',
    twitterImage: 'https://firebasestorage.googleapis.com/v0/b/csquaredmediallc.appspot.com/o/C2%20Media%20Logo_BC.png?alt=media&token=a3a9308b-cef8-4db3-a7d4-082c81e8a931',
    twitterCard: 'summary_large_image'
  })
})
</script>

<style>
#app {
  font-family: 'Roboto', 'Raleway', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;

}


body {
  margin: 0 auto;
  max-width: 2000px;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
