import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "policy" }
const _hoisted_2 = {
  key: 0,
  class: "loading-policy"
}
const _hoisted_3 = {
  key: 1,
  class: "policy-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "policy-load-error"
}

import { getPrivacyAndProceduresPolicy } from "@/composables/useFirebase";
import { ref, onMounted } from "vue";
import { Head } from "@unhead/vue/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'PrivacyProceduresPolicyView',
  setup(__props) {

const privacyPolicySrc = ref<string | null>(null);
const loading = ref(true);

onMounted(async () => {
  privacyPolicySrc.value = await getPrivacyAndProceduresPolicy();
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Head), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("title", null, "Privacy and Procedures Policy | C Squared Media, LLC", -1),
        _createElementVNode("meta", {
          name: "description",
          content: "C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
        }, null, -1),
        _createElementVNode("meta", {
          property: "og:title",
          content: "Privacy and Procedures Policy | C Squared Media, LLC"
        }, null, -1),
        _createElementVNode("meta", {
          property: "og:description",
          content: "C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
        }, null, -1),
        _createElementVNode("meta", {
          name: "twitter:title",
          content: "Privacy and Procedures Policy | C Squared Media, LLC"
        }, null, -1),
        _createElementVNode("meta", {
          name: "twitter:description",
          content: "C Squared Media, LLC is a boutique multimedia marketing agency that helps small businesses and community organizations enhance their digital visibility and reach."
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      (loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("div", {
              class: "spinner-grow",
              role: "status"
            }, null, -1),
            _createElementVNode("div", {
              class: "spinner-grow",
              role: "status"
            }, null, -1),
            _createElementVNode("div", {
              class: "spinner-grow",
              role: "status"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (privacyPolicySrc.value !== null)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 0,
                  src: `https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(
          privacyPolicySrc.value
        )}&embedded=true`,
                  type: "application/pdf",
                  class: "policy-embed"
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "policy-load-error-header" }, "Oops! Something went wrong.", -1),
                  _createElementVNode("span", { class: "policy-load-error-description" }, [
                    _createTextVNode("We could not load the privacy policy. "),
                    _createElementVNode("br"),
                    _createTextVNode("Please contact us directly using the listed contact methods below for assistance. ")
                  ], -1)
                ])))
          ]))
    ])
  ], 64))
}
}

})